import React from 'react';
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';
import {defaultMapConfig} from './map_config';
import {Typography} from '@material-ui/core';

const MapComponent = withScriptjs(withGoogleMap((props: IMapComponentProps) => {
  const position = {
    lat: parseFloat(props.lat),
    lng: parseFloat(props.lng)
  };
  return (
    <GoogleMap
      defaultZoom={17}
      defaultOptions={defaultMapConfig}
      center={position}
    >
      <Marker position={position}>
        <InfoWindow>
          <div style={{overflow: 'hidden'}}>
            <Typography variant="body1">{props.title}</Typography>
            <img
              src={props.thumbnailSource}
              alt={props.title}
            />
          </div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  )
}));

export default MapComponent;

export interface IMapComponentProps {
  lat: string;
  lng: string;
  title: string;
  thumbnailSource: string;
}