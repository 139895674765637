import React from 'react';
import {
  createStyles,
  GridListTile,
  GridListTileBar,
  IconButton, makeStyles,
  Tooltip,
} from '@material-ui/core';
import MapIcon from '@material-ui/icons/Place';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import TagIcon from '@material-ui/icons/Publish';
import {BLUE, GREY, YELLOW} from '../../utils/constants';

const PhotoTile: React.FC<IPhotoTileProps> = (props) => {
  const classes = useStyles();
  const tagIconTitle = `Click to ${props.isTagged ? 'Remove ' : ''}Tag`;
  const tagIconColor = props.isTagged ? classes.blue : classes.yellow;
  return (
    <GridListTile classes={{tile: classes.myHeight}}>
      <img
        src={props.thumbnailSource}
        alt={props.thumbnailTitle}
        onClick={props.viewFullscreen}
      />
      <GridListTileBar
        title={props.title}
        subtitle={props.subTitle}
        actionIcon={
          <div className={classes.tileActions}>
            {props.hasGeoTag && (
              <Tooltip placement="top-start" title="View On Map">
                <IconButton onClick={props.viewOnMap}>
                  <MapIcon className={classes.grey}/>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip placement="top-start" title="Download Full Size Image">
              <IconButton onClick={props.download}>
                <DownloadIcon className={classes.grey}/>
              </IconButton>
            </Tooltip>
            <Tooltip placement="top-start" title={tagIconTitle}>
              <IconButton onClick={props.tag}>
                <TagIcon className={tagIconColor}/>
              </IconButton>
            </Tooltip>
          </div>
        }
      />
    </GridListTile>
  );
};

const useStyles = makeStyles(() => createStyles({
  myHeight:{
    height: '40vh'
  },
  tileActions: {
    width: '10rem'
  },
  grey: {
    color: GREY
  },
  yellow: {
    color: YELLOW
  },
  blue: {
    color: BLUE
  }
}));

export default PhotoTile;

export interface IPhotoProps {
  title: string;
  subTitle: string;
  isTagged: boolean;
  hasGeoTag: boolean;
  thumbnailSource: string;
  thumbnailTitle: string;
  imageSource: string;
  tag(): void;
  viewFullscreen(): void;
  viewOnMap(): void;
  download(): void;
}

interface IPhotoTileProps extends IPhotoProps {
}