import React, {PureComponent} from 'react';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles
} from '@material-ui/core';
import SlideUpTransitionComponent from '../../utils/SlideUpTransitionComponent';
import ActionButtons from './ActionButtons';

const initialState: IImageViewerState = {};

class ImageViewer extends PureComponent<IImageViewerProps, IImageViewerState> {
  private div: any;
  constructor(props: IImageViewerProps) {
    super(props);
    this.state = initialState;
    this.div = React.createRef();
  }

  componentDidMount() {
    this.focus();
  }

  focus = () => {
    if (this.div?.current?.focus) {
      this.div.current.focus();
    }
  };

  keyNav = (e: any) => {
    switch (e.keyCode) {
      case 39: this.props.nextImage();
      break;
      case 37: this.props.prevImage();
      break;
      default: return;
    }
  };

  render() {
    const {
      isOpen, imageSource, closeViewer,
      prevImage, nextImage, download, hasNext, hasPrev,
      isTagged, tagImage, title
    } = this.props;
    return (
      <Dialog
        open={isOpen}
        fullScreen={true}
        TransitionComponent={SlideUpTransitionComponent}
        onClose={closeViewer}
        onEntered={this.focus}
        onBackdropClick={closeViewer}
        keepMounted={false}
      >
        <DialogContent>
          <div ref={this.div} onKeyDown={this.keyNav} tabIndex={0}>
            <img
              style={{position: 'relative', maxHeight: '100%', maxWidth: '100%', margin: '0 auto'}} src={imageSource} alt={title}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ActionButtons
            title={title}
            tag={tagImage}
            download={download}
            isTagged={isTagged}
            close={closeViewer}
            hasNext={hasNext}
            hasPrev={hasPrev}
            nextImage={nextImage}
            prevImage={prevImage}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default ImageViewer;

interface IImageViewerProps {
  isOpen: boolean;
  imageSource: string;
  isTagged: boolean;
  hasNext: boolean;
  hasPrev: boolean;
  title: string;
  prevImage(): void;
  nextImage(): void;
  tagImage(): void;
  download(): void;
  closeViewer(): void;
}

interface IImageViewerState {}
