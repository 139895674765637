export const defaultMapConfig: google.maps.MapOptions = {
  fullscreenControl: false,
  panControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  styles: [
    {
      'featureType': 'poi',
      'stylers': [{'visibility': 'off'}]
    }, {
      'featureType': 'poi.park',
      'stylers': [{'visibility': 'simplified'}]
    }, {
      'elementType': 'labels',
      'featureType': 'poi.park',
      'stylers': [{'visibility': 'off'}]
    }, {
      'featureType': 'poi.school',
      'stylers': [{'visibility': 'on'}]
    }, {
      'featureType': 'road',
      'stylers': [{'visibility': 'off'}]
    }, {
      'featureType': 'road.arterial',
      'stylers': [{'visibility': 'simplified'}]
    }, {
      'featureType': 'road.highway',
      'stylers': [{'visibility': 'simplified'}]
    }, {
      'featureType': 'road.local',
      'stylers': [{'visibility': 'simplified'}]
    }, {
      'featureType': 'transit',
      'stylers': [{'visibility': 'off'}]
    }
  ]
};