export const CDATA_AUTH = 'x-cdata-authtoken';
export const CDATA_AUTH_TOKEN = '9b2P7g8r0Q1a1j8Y2g1u';
export const BASE_URL = 'https://pcm.sandi.net/apiserver/api.rsc/';
export const UNIFIER_PHOTOS_API = 'unifierProjectPhotos';
export const DRONE_PHOTOS_API = 'dronePhotosGallery';
export const TAGGED_PHOTOS_API = 'unifierPhotoTags';
export const UNIFIER_PROJECT_INFO_API = 'unifierProjectInfo';
export const PHOTOS_PER_PAGE = 8;
export const YELLOW = '#eaf736';
export const BLUE = '#3687f7';
export const GREY = 'rgba(255, 255, 255, 0.54)';
export const FACILITIES = 1001;