import React from 'react';
import {MenuItem, Select} from '@material-ui/core';
import Paginate from 'react-paginate';

const Pager = (props: IPagerProps) => {
  // create dropdown select options for go to page
  const options = [];
  for(let i = 0; i < props.pages; i++){
    options.push(
      <MenuItem value={i} key={i}>{i+1}</MenuItem>
    )
  }
  const onChange = (e: any)=>{
    props.goToPage(e.target.value);
  };
  const onPageChange = (selected: { selected: number }): void => {
    props.goToPage(selected.selected);
  };
  return (
    <>
      <Select
        value={props.currentPage}
        onChange={onChange}
      >
        {options}
      </Select>
      <Paginate
        pageCount={props.pages}
        pageRangeDisplayed={8}
        marginPagesDisplayed={2}
        onPageChange={onPageChange}
        forcePage={props.currentPage}
        containerClassName="paginate-container"
        pageClassName="paginate-page" /*class on li*/
        pageLinkClassName="paginate-page-link" /*class on anchor*/
        activeClassName="paginate-active"
        previousClassName="paginate-previous" /*li tag of prev button*/
        previousLinkClassName="paginate-prev-link" /*anchor tag of prev button*/
        nextClassName="paginate-next" /*li tag of next button*/
        nextLinkClassName="paginate-next-link" /*tag of next button*/
        disabledClassName="paginate-disabled" /*disabled prev and next buttons*/
      />
    </>
  );
};

export default Pager;

export interface IPagerProps {
  pages: number;
  currentPage: number;
  goToPage(page: number): void;
}