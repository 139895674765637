import Ajax, {AxiosResponse} from 'axios';
import {BASE_URL, CDATA_AUTH, CDATA_AUTH_TOKEN} from './constants';
import {IODataCountResponse} from './types';

export default Ajax.create({
  baseURL: BASE_URL,
  headers: {
    [CDATA_AUTH]: CDATA_AUTH_TOKEN
  }
})

export const getRes = <T>(res: AxiosResponse<IODataCountResponse<T>>): IApiCountResponse<T> => ({
  pages: res.data['@odata.count'],
  value: res.data.value
});

export interface IApiCountResponse<T> {
  pages: number;
  value: T;
}