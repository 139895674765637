import axios from 'axios';
/**
 * Artificially downloads a file, return boolean
 * @param params
 */
export const downloadFromUrl = async (params: IDownloadParams) => {
  try {
    // download file
    const res = await axios.get(params.url);
    console.log(res);
    const {data} = res;
    // create html to trigger file download
    const element = document.createElement('a');
    element.setAttribute('href', 'data:octet-stream;charset=utf-8,' + data);
    element.setAttribute('download', params.fileName);
    element.setAttribute('target', '_self');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (err) {
    return err;
  }


};

interface IDownloadParams {
  url: string;
  fileName: string;
}