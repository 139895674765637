import React, {PureComponent} from 'react';
import App from './App';
import groupBy from 'lodash.groupby';
import {IGroup} from '../utils/types';
import {IMapComponentProps} from './MapViewer/MapComponent';
import {getProjectInfo, ICreateImageTag, IGetPhotoParams, tagImage, TImageSource, unTagPhoto} from '../utils/dbActions';
import {getImages, TImageType} from '../actions';
import {FACILITIES} from '../utils/constants';
import {CssBaseline} from '@material-ui/core';

const tWin: any = window;
const {postMessage, constants: {GET_TAB_INFO}} = tWin.unifierExtensions;
const TaggedTab = 2;

const initialViewImageState: IImageViewerProps = {
  viewImageSource: '', viewImageTitle: '', viewImageCampus: '',
  hasNextImage: false, hasPrevImage: false, viewImageIsTagged: false
};

const initialMapViewerState: IViewMapProps = {
  mapViewerLat: '', mapViewerLng: '', mapViewerThumbnailSource: '', mapViewerTitle: ''
};

const initialState: IAppState = {
  tabIdx: 0, currentPage: 0, pages: 0, gridImages: {},
  imageViewerOpen: false, viewImageIndex: 0, mapViewerOpen: false,
  isFacilities: false, projectNo: '', projectTitle: '', deptid: '',
  spin: true,
  ...initialViewImageState,
  ...initialMapViewerState
};

class AppContainer extends PureComponent<IAppProps,IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    /*
    * Attach listener for unifier extension messaging system.
    * If development, set PID
    * */
    if (process.env.NODE_ENV === 'development') {
      // 1768 - Johnson ES WSM (No Aerial Photos)
      // 1918 - Audubon WSM
      // 1751 - hancock
      // 1985 - Adams ES WSM
      // 1880 - Mann MS WSM Phase 1
      // 1001 - Facilities;
      const pid = 1751;
      // @ts-ignore tslint gets annoyed that pid might not always be 1001
      if (pid === FACILITIES) {
        return new Promise((resolve) => {
          this.setState({
            isFacilities: true,
            tabIdx: TaggedTab
          }, resolve)
        })
          .then(this.getPhotos)
      } else {
        return this.getProjectInfo(pid)
          .then(this.getPhotos)
      }
    } else {
      // production, add event listener for unifier IFC system
      window.addEventListener('message', this.unifierMessage);
      // ask unifier which project it is in
      postMessage({type: GET_TAB_INFO, project_id: '1'}, document.referrer);
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('message', this.unifierMessage)
  }

  componentDidUpdate(prevProps: IAppProps, prevState: IAppState) {
    // invoked immediately after DOM updates, not for initial 'render'
    const {tabIdx, currentPage} = this.state;
    // get photos when tab changes page changes
    if(prevState.tabIdx !== tabIdx || prevState.currentPage !== currentPage) {
      this.getPhotos();
    }
  }

  unifierMessage = (e: any) => {
    if (e.origin === 'https://unifierphotogallery.cisweb.net') { return; }
    if (e?.data?.pid) {
      const {pid} = e.data;
      console.log(`Received pid ${pid}`);
      if(pid == FACILITIES) {
        return new Promise((resolve) => {
          this.setState({
            isFacilities: true,
            tabIdx: TaggedTab
          }, resolve)
        })
          .then(this.getPhotos)
      } else {
        return this.getProjectInfo(pid)
          .then(this.getPhotos)
      }
    } else {
      console.log('Improper request/response');
    }
};

  // Data Operations
  // ====================================
  getProjectInfo = (pid: number) => {
    return getProjectInfo(pid)
      .then((res) => {
        const {
          projectNo, projectTitle
        } = res.value[0];
        this.setState({
          projectNo, projectTitle
        })
      })
  };

  getPhotos = () => {
    const {
      tabIdx, projectNo,
      deptid, currentPage
    } = this.state;

    const params: IGetPhotoParams = {
      projectNo, deptid, getCount: true, page: currentPage
    };

    // order of tabs
    const getPhotos: TImageType[] = ['unifier', 'drone', 'tagged'];
    return new Promise((resolve) => {
      this.setState({spin: true}, resolve);
    })
      .then(() => {
        return getImages(getPhotos[tabIdx], params);
      })
      .then((res) => {
        this.setState({
          pages: res.pages,
          gridImages: groupBy(res.value, 'campus'),
          spin: false
        });
      })
  };

  onTag = (campus: string, imageIdx: number) => {
    const {id, ...rest} = this.state.gridImages[campus][imageIdx];
    if(rest.isTagged && rest.tagId) {
      return this.unTagPhoto(rest.tagId)
        .then(this.getPhotos);
    } else {
      const tag: ICreateImageTag = {
        ...rest,
        sourceId: id
      };
      return this.tagPhoto(tag)
        .then(this.getPhotos);
    }
  };

  tagPhoto = (taggedImage: ICreateImageTag) => tagImage(taggedImage);

  unTagPhoto = (tagId: number) => unTagPhoto(tagId);
  // End Data Operations
  // ====================================

  // Image Viewer Controls
  // ====================================
  openImageViewer = (campus: string, imageIndex: number) => {
    const image = this.state.gridImages[campus][imageIndex];
    const newState: IOpenImageViewerProps = {
      imageViewerOpen: true,
      viewImageIsTagged: image.isTagged,
      viewImageTitle: image.title,
      viewImageSource: image.imageSource,
      viewImageCampus: campus,
      viewImageIndex: imageIndex,
      hasPrevImage: this.hasPrevImage(campus, imageIndex),
      hasNextImage: this.hasNextImage(campus, imageIndex)
    };
    this.setState(newState);
  };

  closeImageViewer = () => this.setState({
    ...initialViewImageState,
    imageViewerOpen: false,
    viewImageIndex: 0
  });

  viewNextImage = () => {
    // early exit, current image in middle of grid, display image
    const {viewImageIndex, viewImageCampus, gridImages} = this.state;
    if(viewImageIndex < gridImages[viewImageCampus].length -1) {
      return this.openImageViewer(viewImageCampus, viewImageIndex+1);
    }
    // go on to next campus image
    const campuses = this._getGridCampuses();
    const cIdx = campuses.indexOf(viewImageCampus);
    const nextCampus = campuses[cIdx+1];
    return this.openImageViewer(nextCampus, 0);
  };

  viewPrevImage = () => {
    // early exit, current image in middle of grid, display image
    const {viewImageIndex, viewImageCampus, gridImages} = this.state;
    if(viewImageIndex > 0) {
      return this.openImageViewer(viewImageCampus, viewImageIndex-1);
    }
    // go on to previous campus image
    const campuses = this._getGridCampuses();
    // get index of current campus
    const cIdx = campuses.indexOf(viewImageCampus);
    const prevCampus = campuses[cIdx-1];
    const prevCampusImages = gridImages[prevCampus];
    if(cIdx > 0 && prevCampusImages.length) {
      return this.openImageViewer(prevCampus, prevCampusImages.length -1);
    }
  };

  hasPrevImage = (viewImageCampus: string, imageViewerIndex: number): boolean => {
    const {gridImages} = this.state;
    // early exit, check if currently viewed image is in middle of grid
    if(imageViewerIndex > 0) {
      return true;
    }
    // if image at start of campus' images, check if there is a previous campus with images
    const campuses = this._getGridCampuses();
    // get index of current campus
    const cIdx = campuses.indexOf(viewImageCampus);
    // check if previous campus with images
    const prevCampus = campuses[cIdx-1];
    return !!(cIdx > 0 && gridImages[prevCampus]);
  };

  hasNextImage = (viewImageCampus: string, imageViewerIndex: number): boolean => {
    const {gridImages} = this.state;
    // early exit, check if currently viewed image is in middle of grid
    if(gridImages[viewImageCampus] && imageViewerIndex < gridImages[viewImageCampus].length-1) {
      return true;
    }
    // if image is at end of campus' images and there is another campus
    const campuses = this._getGridCampuses();
    const cIdx = campuses.indexOf(viewImageCampus);
    const nextCampus = campuses[cIdx+1];
    return !!(imageViewerIndex === gridImages[viewImageCampus].length - 1 && gridImages[nextCampus] && gridImages[nextCampus].length);
  };

  _getGridCampuses = () => {
    return Object.keys(this.state.gridImages).sort();
  };
  // End Image Viewer Controls
  // ====================================

  goToPage = (currentPage: number): void => {
    this.setState({currentPage})
  };

  selectTab = (tabIdx: number) => {
    this.setState({tabIdx, currentPage: 0})
  };

  closeMapViewer = () => {
    const newState: IViewMapProps & Pick<IAppState,'mapViewerOpen'> = {
      mapViewerLat: '',
      mapViewerLng: '',
      mapViewerOpen: false,
      mapViewerThumbnailSource: '',
      mapViewerTitle: ''
    };
    this.setState(newState)
  };

  viewMap = (image: IMapComponentProps) => {
    const newState: IViewMapProps & Pick<IAppState,'mapViewerOpen'> = {
      mapViewerLat: image.lat,
      mapViewerLng: image.lng,
      mapViewerOpen: true,
      mapViewerThumbnailSource: image.thumbnailSource,
      mapViewerTitle: image.title
    };
    this.setState(newState)
  };

  render() {
    const {
      pages,currentPage,gridImages,tabIdx,imageViewerOpen,
      hasNextImage,hasPrevImage,viewImageIsTagged,viewImageSource,
      viewImageTitle, viewImageCampus, mapViewerLng, mapViewerLat, mapViewerThumbnailSource,
      mapViewerTitle, mapViewerOpen, viewImageIndex, isFacilities, spin
    } = this.state;
    const tabs = ['Unifier Photos','Aerial Photos','Tagged Photos'];
    if(isFacilities) {
      // remove Unifier & Aerial tabs
      tabs.shift();
      tabs.shift();
    }
    return (
      <>
        <CssBaseline />
        <App
          pages={pages}
          currentPage={currentPage}
          goToPage={this.goToPage}
          images={gridImages}
          tabs={tabs}
          onTabSelect={this.selectTab}
          tabIdx={tabIdx}
          spin={spin}
          onTag={this.onTag}
          closeImageViewer={this.closeImageViewer}
          imageViewerOpen={imageViewerOpen}
          hasNextImage={hasNextImage}
          hasPrevImage={hasPrevImage}
          viewImage={this.openImageViewer}
          viewImageIsTagged={viewImageIsTagged}
          viewImageSource={viewImageSource}
          viewImageTitle={viewImageTitle}
          viewImageCampus={viewImageCampus}
          viewImageIndex={viewImageIndex}
          viewNextImage={this.viewNextImage}
          viewPrevImage={this.viewPrevImage}
          closeMapViewer={this.closeMapViewer}
          viewMap={this.viewMap}
          mapViewerLat={mapViewerLat}
          mapViewerLng={mapViewerLng}
          mapViewerOpen={mapViewerOpen}
          mapViewerThumbnailSource={mapViewerThumbnailSource}
          mapViewerTitle={mapViewerTitle}
        />
      </>
    );
  }
}

export default AppContainer;

interface IAppProps {

}

interface IAppState extends IImageViewerProps, IViewMapProps {
  spin: boolean;
  tabIdx: number;
  pages: number;
  deptid: string;
  projectNo: string;
  projectTitle: string;
  currentPage: number;
  isFacilities: boolean;
  viewImageIndex: number;
  imageViewerOpen: boolean;
  mapViewerOpen: boolean;
  gridImages: IGroup<IPhoto>;
}

export interface IViewMapProps {
  mapViewerLat: string;
  mapViewerLng: string;
  mapViewerThumbnailSource: string;
  mapViewerTitle: string;
}

export interface IPhoto {
  id: number;
  campus: string;
  latDeg: string;
  lonDeg: string;
  isTagged: boolean;
  tagId?: number;
  imageSource: string;
  thumbnailSource: string;
  title: string;
  imageDate: string;
  dateTaken: string;
  deptid: string;
  fileName: string;
  projectNo: string;
  source: TImageSource;
}

export interface IImageViewerProps {
  viewImageTitle: string;
  viewImageSource: string;
  viewImageIsTagged: boolean;
  viewImageCampus: string;
  hasPrevImage: boolean;
  hasNextImage: boolean;
}

export interface IImageViewerOps {
  closeImageViewer(): void;
  viewPrevImage(): void;
  viewNextImage(): void;
}

export interface IOpenImageViewerProps extends Pick<IAppState, 'imageViewerOpen' |
  'viewImageIsTagged' | 'viewImageTitle' | 'viewImageSource' | 'viewImageCampus' |
  'viewImageIndex' | 'hasPrevImage' | 'hasNextImage'>{
}