import React from 'react';
import Pager, {IPagerProps} from './Pager';
import PhotoGrid from './PhotoGrid/PhotoGrid';
import PhotoTile from './PhotoGrid/PhotoTile';
import {IGroup} from '../utils/types';
import HeaderTile from './PhotoGrid/HeaderTile';
import {IImageViewerOps, IImageViewerProps, IPhoto} from './App.container';
import {downloadFromUrl} from '../utils/downloadImage';
import {createStyles, makeStyles, Tab, Tabs, Theme} from '@material-ui/core';
import ImageViewer from './ImageViewer/ImageViewer';
import MapDialog from './MapViewer/MapDialog';
import {IMapComponentProps} from './MapViewer/MapComponent';
import Spinner from './Spinner';

const noop = () => alert('Open full size image, Right Click > Save Image As');

const App: React.FC<IAppProps> = (props) => {
  const {
    currentPage, goToPage, pages, imageViewerOpen, viewImageTitle,
    hasNextImage, hasPrevImage, viewNextImage, viewPrevImage,
    viewImageIsTagged, viewImageSource, closeImageViewer, viewImage,
    mapViewerOpen, closeMapViewer, mapViewerLat, mapViewerLng, mapViewerThumbnailSource,
    mapViewerTitle, viewMap, onTag, viewImageCampus, viewImageIndex
  } = props;
  const classes = useStyles();
  const tiles: any[] = [];
  Object.keys(props.images)
    .sort()
    .forEach((campus, index, array) => {
      if (array.length > 1) {
        tiles.push(<HeaderTile key={`${campus}-${index}`} name={campus}/>);
      }
      props.images[campus].forEach((image, imageIndex) => {
        const downloadImage = () => downloadFromUrl({url: image.imageSource, fileName: image.title});
        const viewOnMap = () => viewMap({
          thumbnailSource: image.thumbnailSource,
          lat: image.latDeg,
          lng: image.lonDeg,
          title: image.title
        });
        const onTagImage = () => onTag(image.campus, imageIndex);
          tiles.push((
          <PhotoTile
            key={image.id}
            hasGeoTag={(Boolean(image.latDeg) && Boolean(image.lonDeg))}
            isTagged={image.isTagged}
            imageSource={image.imageSource}
            thumbnailSource={image.thumbnailSource}
            thumbnailTitle={image.title}
            title={image.title}
            subTitle={`Uploaded on ${(new Date(image.imageDate).toLocaleDateString())}`}
            download={downloadImage}
            tag={onTagImage}
            viewFullscreen={() => viewImage(campus, imageIndex)}
            viewOnMap={viewOnMap}
          />
        ));
      });
    });
  const onTabChange = (e: any, tabIdx: number) => {
    props.onTabSelect(tabIdx);
  };
  return (
    <div className={classes.container}>
      <Tabs value={props.tabIdx} onChange={onTabChange}>
        {props.tabs.map((tabName, index) => {
          return <Tab key={index} label={tabName}/>
        })}
      </Tabs>
      <PhotoGrid>
        {tiles}
      </PhotoGrid>
      {pages > 1 && (
        <Pager
          currentPage={currentPage}
          goToPage={goToPage}
          pages={pages}
        />
      )}
      {
        imageViewerOpen && (
          <ImageViewer
            isOpen={imageViewerOpen}
            title={viewImageTitle}
            prevImage={viewPrevImage}
            nextImage={viewNextImage}
            hasPrev={hasPrevImage}
            hasNext={hasNextImage}
            isTagged={viewImageIsTagged}
            tagImage={() => onTag(viewImageCampus, viewImageIndex)}
            download={noop}
            imageSource={viewImageSource}
            closeViewer={closeImageViewer}
          />
        )
      }
      {
        mapViewerOpen && (
          <MapDialog
            close={closeMapViewer}
            isOpen={mapViewerOpen}
            title={mapViewerTitle}
            thumbnailSource={mapViewerThumbnailSource}
            lat={mapViewerLat}
            lng={mapViewerLng}
          />
        )
      }
      <Spinner spin={props.spin}/>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  }
}));

export default App;

interface IAppProps extends IPagerProps, IImageViewerProps, IImageViewerOps {
  spin: boolean;
  imageViewerOpen: boolean;
  viewImageIndex: number;
  mapViewerOpen: boolean;
  mapViewerLat: string;
  mapViewerLng: string;
  mapViewerTitle: string;
  mapViewerThumbnailSource: string;
  images: IGroup<IPhoto>;
  tabIdx: number;
  tabs: string[];
  onTabSelect(tabIdx: number): void;
  viewImage(campus: string, imageIdx: number): void;
  viewMap(image: IMapComponentProps): void;
  closeMapViewer(): void;
  onTag(campus: string, i: number): void;
}
