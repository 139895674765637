import React from 'react';
import {Slide} from '@material-ui/core';

const SlideUpTransitionComponent = (props: any) => {
  return (
    <Slide direction="up" {...props} />
  );
};

export default SlideUpTransitionComponent;
