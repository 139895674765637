import React from 'react';
import Spin from 'react-spinkit';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

const Spinner = (props: ISpinnerProps) => {
  const {spinner, container} = props.classes;
  if (props.spin) {
    return (
      <>
        <div className={`${container}`}/>
        <Spin className={spinner} name="cube-grid" color="#333"/>
      </>
    );
  } else {
    return null;
  }
};

const styles = createStyles({
  container: {
    background: 'rgba(255,255,255,0.82)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 500,
  },
  spinner: {
    height: '5rem',
    left: '45%',
    position: 'fixed',
    top: '45%',
    width: '5rem',
    zIndex: 2000,
  }
});

export default withStyles(styles)(Spinner);

interface ISpinnerProps extends WithStyles<typeof styles> {
  spin: boolean;
}