import {
  getDronePhotos,
  getTaggedPhotos,
  getUnifierPhotos,
  IDronePhoto,
  IGetPhotoParams, ITaggedPhoto,
  IUnifierPhoto, TDroneRes, TTaggedRes, TUnifierRes
} from './utils/dbActions';
import {IPhoto} from './components/App.container';
import {PHOTOS_PER_PAGE} from './utils/constants';
import {IApiCountResponse} from './utils/ajax';


export const getImages = (type: TImageType, filter: IGetPhotoParams): PromiseLike<IApiCountResponse<IPhoto[]>> => {
  const getPhotos: IPhotoQueries = {
    unifier: getUnifierPhotos,
    drone: getDronePhotos,
    tagged: getTaggedPhotos
  };

  const photoAdapters: IPhotoAdapters = {
    unifier: unifierPhotoAdapter,
    drone: dronePhotoAdapter,
    tagged: taggedPhotoAdapter
  };
  const fn = getPhotos[type];
  // @ts-ignore
  return fn(filter)
    // @ts-ignore
    .then((res: TDroneRes | TTaggedRes | TUnifierRes) => {
      const ret: IApiCountResponse<IPhoto[]> = {
        pages: Math.ceil(res.pages/PHOTOS_PER_PAGE),
        // @ts-ignore -- not all properties exist in union type which is fine since adapter will handle
        value: photoAdapters[type](res.value)
      };
      return ret;
    });
};

type IPhotoQueries = {
  [prop in TImageType]: typeof getDronePhotos | typeof getTaggedPhotos | typeof getUnifierPhotos;
};

export type TImageType = 'unifier' | 'drone' | 'tagged';

type IPhotoAdapters = {
  [prop in TImageType]: typeof unifierPhotoAdapter | typeof dronePhotoAdapter | typeof taggedPhotoAdapter
}

const unifierPhotoAdapter = (photos: IUnifierPhoto[]): IPhoto[] => {
  return photos.map(mapper);
  function mapper (photo: IUnifierPhoto): IPhoto {
    return {
      tagId: photo.tagId, source: 'unifier', projectNo: photo.projectNo,
      fileName: photo.fileName, deptid: photo.deptid, dateTaken: photo.dateTaken,
      title: photo.fileName, thumbnailSource: photo.thumbnailSource,
      imageSource: photo.imageSource, campus: photo.campus, lonDeg: '', latDeg: '',
      isTagged: photo.isTagged, id: photo.id, imageDate: photo.dateTaken
    }
  }
};

const dronePhotoAdapter = (photos: IDronePhoto[]): IPhoto[] => {
  return photos.map(mapper);
  function mapper(photo: IDronePhoto): IPhoto {
    return {
      imageDate: photo.dateTaken, id: photo.id, isTagged: photo.isTagged,
      latDeg: photo.latDeg, lonDeg: photo.lonDeg, imageSource: photo.imageSource,
      thumbnailSource: photo.thumbnailSource, title: photo.title, dateTaken: photo.dateTaken,
      deptid: photo.deptid, fileName: photo.fileName, projectNo: photo.projectName,
      source: 'drone', tagId: photo.tagId, campus: photo.campus
    }
  }
};

const taggedPhotoAdapter = (photos: ITaggedPhoto[]): IPhoto[] => {
  return photos.map(mapper);

  function mapper(photo: ITaggedPhoto): IPhoto {
    return {
      campus: photo.campus,
      dateTaken: photo.dateTaken,
      deptid: photo.deptid,
      fileName: photo.fileName,
      id: photo.id,
      imageDate: photo.dateTaken,
      imageSource: photo.imageSource,
      isTagged: true,
      latDeg: "",
      lonDeg: "",
      projectNo: photo.projectNo,
      source: photo.source,
      tagId: photo.id,
      thumbnailSource: photo.thumbnailSource,
      title: photo.title
    }
  }
};