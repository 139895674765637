import React from 'react';
import {createStyles, GridListTile, makeStyles, Typography} from '@material-ui/core';

const HeaderTile: React.FC<IHeaderTileProps> = (props) => {
  const classes = useStyles();
  return (
    <GridListTile
      cols={1}
      className={classes.tile}
    >
      <Typography variant="h6">{props.name}</Typography>
    </GridListTile>
  );
};

const useStyles = makeStyles(() => createStyles({
  tile: {
    height: 'auto',
    minWidth: '15vw',
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'space-around'
  },
}));

export default HeaderTile;

interface IHeaderTileProps {
  name: string;
}