import React from 'react';
import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Left from '@material-ui/icons/ChevronLeft';
import Right from '@material-ui/icons/ChevronRight';
import Download from '@material-ui/icons/CloudDownload';
import Tag from '@material-ui/icons/Publish';
import {BLUE, YELLOW} from '../../utils/constants';

const ActionButtons: React.FC<IActionButtonsProps> = (props) => {
  const {
    close, download, isTagged, nextImage, prevImage, tag, title,
    hasNext, hasPrev
  } = props;
  const classes = useStyles();
  const tagIconTitle = `Click to ${props.isTagged ? 'Remove ' : ''}Tag`;
  const tagIconColor = isTagged ? classes.blue : classes.yellow;
  return (
    <>
      <Typography variant="body2">{title}</Typography>
      <IconButton
        color="primary"
        onClick={prevImage}
        disabled={!hasPrev}
      ><Left/></IconButton>
      <IconButton
        color="primary"
        onClick={nextImage}
        disabled={!hasNext}
      ><Right/></IconButton>
      <IconButton onClick={download}><Download/></IconButton>
      <Tooltip placement="top-start" title={tagIconTitle}>
        <IconButton onClick={tag}><Tag className={tagIconColor}/></IconButton>
      </Tooltip>
      <Button color="secondary" onClick={close}>Close</Button>
    </>
  );
};

const useStyles = makeStyles(() => createStyles({
  yellow: {
    color: YELLOW
  },
  blue: {
    color: BLUE
  }
}));

export default ActionButtons;

interface IActionButtonsProps {
  isTagged: boolean;
  hasPrev: boolean;
  hasNext: boolean;
  title: string;
  prevImage(): void;
  nextImage(): void;
  close(): void;
  tag(): void;
  download(): void;
}