import React from 'react';
import {createStyles, GridList, makeStyles, Theme} from '@material-ui/core';

const PhotoGrid: React.FC<IPhotoGridProps> = (props) => {
  const classes = useStyles();
  return (
    <GridList
      className={classes.grid}
      cellHeight={150}
      cols={4}
    >
      {props.children}
    </GridList>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  grid: {
    backgroundColor: theme.palette.background.paper,
    width: '100vw',
    height: '80vh',
    paddingTop: '1rem',
    overflow: 'hidden',
    justifyContent: 'center',
    flexWrap: 'wrap',
  }
}));

export default PhotoGrid;

interface IPhotoGridProps {
  children: any;
}