import React from 'react';
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import SlideUpTransitionComponent from '../../utils/SlideUpTransitionComponent';
import MapComponent, {IMapComponentProps} from './MapComponent';

const MapDialog: React.FC<IMapDialogProps> = (props) => {
  return (
    <Dialog
      fullScreen={true}
      open={props.isOpen}
      onClose={props.close}
      TransitionComponent={SlideUpTransitionComponent}
      onBackdropClick={props.close}
      keepMounted={false}
    >
      <DialogContent>
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBZSGowwfRB3xyizmsXmZoO10wOUtu-ZzU"
          loadingElement={<div style={{height: '100%'}}/>}
          containerElement={<div style={{height: '60vw', overflow: 'hidden'}}/>}
          mapElement={<div style={{height: '100%'}}/>}
          title={props.title}
          thumbnailSource={props.thumbnailSource}
          lat={props.lat}
          lng={props.lng}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={props.close}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapDialog;

interface IMapDialogProps extends IMapComponentProps{
  isOpen: boolean;
  close(): void;
}