import {IODataParams, IODataResponse} from './types';
import ajax, {getRes, IApiCountResponse} from './ajax';
import {
  DRONE_PHOTOS_API,
  PHOTOS_PER_PAGE,
  TAGGED_PHOTOS_API,
  UNIFIER_PHOTOS_API,
  UNIFIER_PROJECT_INFO_API
} from './constants';

export const getUnifierPhotos = (query: IGetPhotoParams): PromiseLike<TUnifierRes> => {
  const {page,projectNo} = query;
  const params: IODataParams = {
    $top: PHOTOS_PER_PAGE,
    $skip: PHOTOS_PER_PAGE * page,
    $count: 'true',
    $filter: `projectNo eq '${projectNo}'`,
    $orderby: 'dateTaken desc'
  };

  return ajax.get(UNIFIER_PHOTOS_API, {params})
    .then((res) => {
      return getRes<IUnifierPhoto[]>(res);
    })
};

export type TUnifierRes = IApiCountResponse<IUnifierPhoto[]>;

export interface IUnifierPhoto {
  id: number;
  campus: string;
  dateTaken: string;
  deptid: string;
  fileName: string;
  imageSource: string;
  thumbnailSource: string;
  isTagged: boolean;
  tagId?: number;
  projectId: number;
  projectNo: string;
  source: 'unifier';
}

export const getDronePhotos = (query: IGetPhotoParams): PromiseLike<TDroneRes> => {
  const {page,projectNo} = query;
  const params: IODataParams = {
    $top: PHOTOS_PER_PAGE,
    $skip: PHOTOS_PER_PAGE * page,
    $count: 'true',
    $filter: `projectName eq '${projectNo}'`,
    $orderby: 'dateTaken desc'
  };

  return ajax.get(DRONE_PHOTOS_API, {params})
    .then((res) => {
      return getRes<IDronePhoto[]>(res);
    })
};

export type TDroneRes = IApiCountResponse<IDronePhoto[]>;

export interface IDronePhoto {
  fileName: string;
  id: number;
  campus: string;
  deptid: string;
  projectName: string;
  title: string;
  imageSource: string;
  thumbnailSource: string;
  dateTaken: string;
  source: 'drone';
  isTagged: boolean;
  tagId?: number;
  latDeg: string;
  lonDeg: string;
}

export const getTaggedPhotos = (query: IGetPhotoParams): PromiseLike<TTaggedRes>  => {
  const {page,projectNo} = query;
  const params: IODataParams = {
    $top: PHOTOS_PER_PAGE,
    $skip: PHOTOS_PER_PAGE * page,
    $count: 'true',
    $filter: `projectNo eq '${projectNo}'`,
    $orderby: 'campus,dateAdded asc'
  };

  return ajax.get(TAGGED_PHOTOS_API, {params})
    .then((res) => {
      return getRes<ITaggedPhoto[]>(res);
    })
};

export type TTaggedRes = IApiCountResponse<ITaggedPhoto[]>;

export interface ITaggedPhoto {
  id: number;
  campus: string;
  dateAdded: string;
  dateTaken: string;
  deptid: string;
  fileName: string;
  imageSource: string;
  projectNo: string;
  projectTitle: string;
  source: TImageSource;
  thumbnailSource: string;
  title: string;
}

export type TImageSource = 'unifier' | 'drone';

export const tagImage = (image: ICreateImageTag) => {
  return ajax.post(TAGGED_PHOTOS_API, image)
};

export interface ICreateImageTag {
  campus: string;
  dateTaken: string;
  deptid: string;
  fileName: string;
  imageSource: string;
  projectNo: string;
  projectTitle?: string;
  source: TImageSource;
  sourceId: number;
  thumbnailSource: string;
  title: string;
}

export const unTagPhoto = (tagId: number) => {
  return ajax.delete(`${TAGGED_PHOTOS_API}(${tagId})`)
};

export interface IGetPhotoParams {
  projectNo?: string;
  deptid?: string;
  getCount: boolean;
  page: number
}

export const getProjectInfo = (pid: number): PromiseLike<IODataResponse<IProject[]>> => {
  const params: IODataParams = {
    $filter: `pid eq ${pid}`,
    $select: 'campus,deptid,projectNo,projectTitle'
  };
  return ajax.get(UNIFIER_PROJECT_INFO_API, {params})
    .then((res) => {
      return getRes<IProject[]>(res)
    })
};

export interface IProject {
  pid: number;
  deptid: string;
  projectTitle: string;
  projectNo: string;
  campus: string;
}